import { propertyCertificatesTypes } from "../constants/PropertyCertificates";

const initialState = {
    propertyCertificates: []
}

export const PropertyCertificates = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case propertyCertificatesTypes.GET_PROPERTY_CERTIFICATES_LIST:
            return { ...state, propertyCertificates: payload.propertyCertificates };
        case propertyCertificatesTypes.GET_PROPERTY_CERTIFICATES_LIST_ERROR:
            return { ...state, propertyCertificates: [] };
        default:
            return initialState
    }
}