import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deletePropertyCertificate, getPropertiesCertificates } from "../../redux/actions/PropertyCertificates";
import { DataTable } from "../../component/DataTable";
import { Loader } from "../../component/Loader";

import "./style.css"
import { useState } from 'react';
import { Paper } from '@material-ui/core';
import { ConfirmDelete } from '../../component/DeleteConfirmDialog';


export function PropertyCertificates() {
    let dispatch = useDispatch();
    let propertyState = useSelector((state) => state.PropertyCertificates)

    const [data, setData] = useState([]);
    const [open, setOpen] = useState(true)

    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [valueToDelete, setValueToDelete] = useState(null)

    function nameSort(first, second, key) {
        return first.landlord?.user[key] > second.landlord?.user[key] ? 1 : -1
    }

    const columns = [
        {
            title: 'Certificate Type',
            field: 'certificate_type',
            render: rowData => `${rowData?.certificate_type?.toUpperCase()}`,
            customFilterAndSearch: (term, rowData) => rowData?.certificate_type?.toUpperCase()?.match(new RegExp(term, 'i')),
            customSort: (a, b) => nameSort(a, b, 'certificate_type')
        },
        {
            title: 'Property',
            field: 'name',
            render: rowData => `${rowData?.certificates_stripe_payments[0]?.property?.name}`,
            customFilterAndSearch: (term, rowData) => rowData?.certificates_stripe_payments[0]?.property?.name?.match(new RegExp(term, 'i')),
            customSort: (a, b) => nameSort(a, b, 'name')
        },
        {
            title: 'User name',
            field: 'first_name',
            render: rowData => `${rowData?.certificates_stripe_payments[0]?.property?.landlord?.user?.first_name}`,
            customFilterAndSearch: (term, rowData) => rowData?.certificates_stripe_payments[0]?.property?.landlord?.user?.first_name?.match(new RegExp(term, 'i')),
            customSort: (a, b) => nameSort(a, b, 'first_name')
        },
        {
            title: 'User email',
            field: 'email',
            render: rowData => `${rowData?.certificates_stripe_payments[0]?.property?.landlord?.user?.email}`,
            customFilterAndSearch: (term, rowData) => rowData?.certificates_stripe_payments[0]?.property?.landlord?.user?.email?.match(new RegExp(term, 'i')),
            customSort: (a, b) => nameSort(a, b, 'email')
        },
        {
            title: 'Amount Paid',
            field: 'amount',
            render: rowData => `${rowData?.certificates_stripe_payments[0]?.amount}`,
            customFilterAndSearch: (term, rowData) => rowData?.certificates_stripe_payments[0]?.amount?.toString()?.match(new RegExp(term, 'i')),
            customSort: (a, b) => nameSort(a, b, 'amount')
        },
        {
            title: 'Status',
            field: 'status',
        },
        {
            title: 'IVB ID',
            field: 'inventory_id',
        },
        {
            title: 'Stripe Payment ID',
            field: 'stripe_payment_id',
            render: rowData => `${rowData?.certificates_stripe_payments[0]?.stripe_payment_id}`,
            customFilterAndSearch: (term, rowData) => rowData?.certificates_stripe_payments[0]?.stripe_payment_id?.match(new RegExp(term, 'i')),
            customSort: (a, b) => nameSort(a, b, 'stripe_payment_id')
        },
    ];

    const options = {
        filtering: true,
        grouping: true,
        actionsColumnIndex: -1
    }

    function getDeleteDialogValue(agree, closeDialog) {
        if (agree) {
            dispatch(deletePropertyCertificate(valueToDelete.buy_certificate_id, "CERTIFICATE"));
        }
        setDeleteOpen(!closeDialog);
    }

    const action = [
        {
            icon: 'delete',
            tooltip: 'Delete Certificate',
            onClick: (event, rowData) => {
                rowData.name = `${rowData.certificate_type}`;
                setDeleteOpen(true); setValueToDelete(rowData)
            }
        },
    ]

    useEffect(() => {
        dispatch(getPropertiesCertificates());
    }, []);

    useEffect(() => {
        setData(propertyState.propertyCertificates)
        setOpen(false);
    }, [propertyState.propertyCertificates])

    return (
        <div>
            <Loader open={open} />
            <Paper elevation={3} >
                <DataTable
                    title="Certificates"
                    columns={columns}
                    data={data}
                    action={action}
                    options={options}
                />
            </Paper>
            <ConfirmDelete open={deleteOpen} getDialogValue={getDeleteDialogValue} valueToDelete={valueToDelete} />
        </div>
    )
}