import { apiLinks } from "../apiCalls";
import { _delete, _get } from "../axios.config";
import { AuthActionTypes } from "../constants/Auth-types";
import { propertyCertificatesTypes } from "../constants/PropertyCertificates";
import { error } from "../../component/Toaster";
import { trackPromise } from 'react-promise-tracker';

export const getPropertiesCertificates = () => {
    return async function (dispatch, getState) {
        try {
            const response = await trackPromise(
                _get(apiLinks.propertyCertificates)
            )
            if (response.status) {
                dispatch({ type: propertyCertificatesTypes.GET_PROPERTY_CERTIFICATES_LIST, payload: response.data })
            } else {
                dispatch({ type: propertyCertificatesTypes.GET_PROPERTY_LIST_ERROR })
                error(response.message)
            }
        } catch (err) {
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}

export const deletePropertyCertificate = (id) => {
    return async function (dispatch, getState) {
        try {
            const replacedUrl = apiLinks.deletePropertyCertificate.replace("certificateId", id);
            const response = await _delete(replacedUrl);
            if (response.status) {
                dispatch(getPropertiesCertificates());
            } else {
                error(response.message)
            }
        } catch (err) {
            // console.log(err)
            dispatch({ type: AuthActionTypes.LOGOUT, payload: { isLoggedIn: false } })
        }
    }
}