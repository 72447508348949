// const baseURL = "http://localhost:5555/v1.0";
const baseURL_prefix = process.env.REACT_APP_API || "https://api.hubita.co.uk/";
const baseURL = baseURL_prefix + "v1.0";

export const apiLinks = {
    login: `${baseURL}/login`,
    getProfile: `${baseURL}/user-profile`,
    getLandlords: `${baseURL}/admin/landlords`,
    properties: `${baseURL}/admin/all-properties`,
    propertyCertificates: `${baseURL}/admin/all-property-buy-certificates`,
    deletePropertyCertificate: `${baseURL}/admin/delete-property-buy-certificate?id=certificateId`,

    getReminderTypes: `${baseURL}/admin/reminders`,
    deleteReminderType: `${baseURL}/admin/delete-reminder?id=reminderTypeId`,
    addReminderType: `${baseURL}/admin/add-reminder`,

    getPaymentStatus: `${baseURL}/admin/payment-status`,
    addPaymentStatus: `${baseURL}/admin/add-payment-status`,
    deletePaymentStatus: `${baseURL}/admin/delete-payment-status?id=paymentStatusId`,

    getPaymentType: `${baseURL}/admin/payment-types`,
    addPaymentType: `${baseURL}/admin/add-payment-type`,
    deletePaymentType: `${baseURL}/admin/delete-payment-type?id=paymentTypeId`,

    getMortgage: `${baseURL}/mortgage-providers`,
    addMortgage: `${baseURL}/admin/add-mortgage-provider`,
    deleteMortgage: `${baseURL}/admin/delete-mortgage-provider?id=mortgageId`,
    updateMortgage: `${baseURL}/admin/edit-mortgage-provider?id=mortgageId`,

    getUtilityMasters: `${baseURL}/admin/get-serviceprovider-master`,
    addUtilityMasters: `${baseURL}/admin/add-serviceprovider-master`,
    deleteUtilityMasters: `${baseURL}/admin/delete-serviceprovider-master?id=utilityMasterId`,
    updateUtilityMasters: `${baseURL}/admin/update-serviceprovider-master?id=utilityMasterId`,

    getUtilityProviders: `${baseURL}/admin/get-serviceprovider`,
    addUtilityProviders: `${baseURL}/admin/add-serviceprovider`,
    deleteUtilityProviders: `${baseURL}/admin/delete-serviceprovider?id=utilityProviderId`,
    updateUtilityProviders: `${baseURL}/admin/update-serviceprovider?id=utilityProviderId`,

    assetsUpload: 'https://assets.hubita.co.uk/uploads',
    getAssets: 'https://assets.hubita.co.uk/',
    uploadPost: `${baseURL}/admin/add-mediahub`,
    getMediaByType: `${baseURL}/admin/get-mediahub`,
    getMediaById: `${baseURL}/admin/get-mediahub-by/postId`,
    updateMediaById: `${baseURL}/admin/update-mediahub-by/postId`,
    deleteMediaById: `${baseURL}/admin/delete-mediahub-by/postId`,
}
