import React from 'react';
import { BrowserRouter, Link, Routes, Route } from 'react-router-dom';
import { PrivateOutlet } from "../../component/PrivateRoute";
import { PublicOutlet } from "../../component/PublicRoute";

import { Login } from "../Login";
import { Dashboard } from "../Dashboard";
import { Landlord } from '../Landlord';
import { Property } from '../Property';
import { Blog, News, Video, CreateMediaHub, EditMediaHub } from '../MediaHub';
import { ReminderType, PaymentStatus, PaymentType, MortgageProvider, UtilityMaster, UtilityProvider } from "../Utilities";
import { ComingSoon } from "../ComingSoon";
import { PropertyCertificates } from '../PropertyCertificates';


export function Layout(props) {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<PublicOutlet />} >
                    <Route path="/" element={<Login />} />
                </Route>
                <Route element={<PrivateOutlet />} >
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/tenants" element={<ComingSoon />} />
                    <Route path="/landlord" element={<Landlord />} />
                    <Route path="/property" element={<Property />} />
                    <Route path="/property/certificates" element={<PropertyCertificates />} />
                    <Route path="/media-hub/NEWS" element={<News />} />
                    <Route path="/media-hub/BLOG" element={<Blog />} />
                    <Route path="/media-hub/VIDEO" element={<Video />} />
                    <Route path="/drop-downs/reminder-type" element={<ReminderType />} />
                    <Route path="/drop-downs/payment-status" element={<PaymentStatus />} />
                    <Route path="/drop-downs/payment-type" element={<PaymentType />} />
                    <Route path="/drop-downs/mortgage-provider" element={<MortgageProvider />} />
                    <Route path="/drop-downs/utilities-master" element={<UtilityMaster />} />
                    <Route path="/drop-downs/utilities-providers" element={<UtilityProvider />} />
                    <Route path="/media-hub/create/:type" element={<CreateMediaHub />}/>
                    <Route path="/media-hub/edit/:type/:id" element={<EditMediaHub />}/>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}