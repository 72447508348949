import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, } from 'react-redux';

import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ArrowRight from '@material-ui/icons/ArrowRight';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import { checkToken } from '../../redux/axios.config';
import { setLoggedInFlag, logoutUser } from '../../redux/actions/Auth';
import { Collapse } from '@material-ui/core';
import { ListItemButton } from '@mui/material';
import {
    Dashboard,
    Group,
    Person,
    Home,
    PermMedia,
    DynamicFeed,
    Book,
    VideoCall,
    Storage,
    NotificationsActive,
    Payment,
    AccountBalanceWallet,
    Business,
    ExitToApp
} from '@material-ui/icons';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(0),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: '#3F51B5',
        color: '#FFFFFF'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    icon: {
        color: '#FFFFFF'
    }
}));

const sideMenus = [
    {
        text: 'Dashboard',
        link: '/dashboard',
        icon: <Dashboard />
    },
    {
        text: 'Tenant',
        link: '/tenants',
        icon: <Group />
    },
    {
        text: 'User',
        link: '/landlord',
        icon: <Person />
    },
    {
        text: 'Property',
        link: '/property',
        icon: <Home />,
        subMenu: [{
            text: 'Certificates',
            link: '/property/certificates',
            icon: <DynamicFeed />
        }],
        isCollapsed: false
    },
    {
        text: 'Media Hub',
        link: '/media-hub/NEWS',
        icon: <PermMedia />,
        subMenu: [{
            text: 'News',
            link: '/media-hub/NEWS',
            icon: <DynamicFeed />
        }, {
            text: 'Blog',
            link: '/media-hub/BLOG',
            icon: <Book />
        }, {
            text: 'Video',
            link: '/media-hub/VIDEO',
            icon: <VideoCall />
        }],
        isCollapsed: false
    },
    {
        text: 'Master Data',
        link: '/drop-downs/reminder-type',
        icon: <Storage />,
        subMenu: [{
            text: 'Reminder Type',
            link: '/drop-downs/reminder-type',
            icon: <NotificationsActive />
        }, {
            text: 'Payment Status',
            link: '/drop-downs/payment-status',
            icon: <AccountBalanceWallet />
        }, {
            text: 'Payment Type',
            link: '/drop-downs/payment-type',
            icon: <Payment />
        }, {
            text: 'Mortagage Provider',
            link: '/drop-downs/mortgage-provider',
            icon: <Business />
        }, {
            text: 'Utility Master',
            link: '/drop-downs/utilities-master',
            icon: <Storage />,
        }, {
            text: 'Utility Provider',
            link: '/drop-downs/utilities-providers',
            icon: <Business />
        },
        ],
        isCollapsed: false
    },
]

export function PrivateOutlet(props) {
    const { window } = props;
    const classes = useStyles();
    const theme = useTheme();

    const [mobileOpen, setMobileOpen] = React.useState(false);

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const routeChange = (path, index = null) => {
        navigate(path)
        if (index) {
            const menu = sideMenus[index];
            if (menu.subMenu && menu.subMenu.length) {                
                menu.isCollapsed = !menu.isCollapsed
            } 
        }
    }

    const handleClick = (index) => {
        const menu = sideMenus[index];
        menu.isCollapsed = !menu.isCollapsed;
    };

    useEffect(() => {
        const isTokenSet = checkToken()
        if (isTokenSet) {
            dispatch(setLoggedInFlag());
        } else {
            dispatch(logoutUser());
            routeChange("")
        }
    }, [])

    function logout() {
        dispatch(logoutUser());
            routeChange("")
    }

    const drawer = (
        <div>
            <Toolbar className='toolbar'>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Hubita
                </Typography>
            </Toolbar>
            <Divider />
            <List>
                {sideMenus.map((row, index) => (
                    <div key={row.text}>
                        <ListItem button  onClick={() => routeChange(row.link, index)}>
                            <ListItemIcon className={classes.icon}>
                                {row.icon}
                            </ListItemIcon>
                            <ListItemText primary={row.text} />
                            {row.subMenu && row.subMenu.length ? (<div>
                                {
                                    !row?.isCollapsed ?
                                        <ArrowRight /> : <ArrowDropDown />
                                }
                            </div>) : null}
                        </ListItem>
                        {
                            row.subMenu && row.subMenu.length ? (
                                <Collapse in={row?.isCollapsed} timeout="auto" unmountOnExit   >
                                    {
                                        row?.subMenu.map((row, index) => (
                                            <List component="div" disablePadding key={row.text}>
                                                <ListItemButton sx={{ pl: 4 }} onClick={() => routeChange(row.link)}>
                                                    <ListItemIcon className={classes.icon}>
                                                        {row.icon}
                                                    </ListItemIcon>
                                                    <ListItemText primary={row.text} />
                                                </ListItemButton>
                                            </List>
                                        ))
                                    }
                                </Collapse>
                            ) : null
                        }
                    </div>
                ))}
            </List>
            <Divider />
            <List>
                {['Logout'].map((text, index) => (
                    <ListItem button key={text} onClick={() => logout()}>
                        <ListItemIcon className={classes.icon}>{<ExitToApp />}</ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                < Outlet />
            </main>
        </div>
    );
}

PrivateOutlet.propTypes = {
    window: PropTypes.func,
};